import React, { useContext, useEffect, useState } from 'react'
import { Link, useLoaderData, useLocation } from 'react-router-dom';

import Search from './Search'
import Lang from './Lang/Lang'

import styles from './header.module.css'
import { store } from '../HOC/State';
import { translate } from '../../Pages/translate';
import Menu from '../Menu/Menu';

export default function HeaderSide() {
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const {pathname} = useLocation()

  return (
    <div className={styles['header-div']}>
    {<Link to='/' className={styles['logo-div']}>
            <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
            <div className={styles['name-div']}>
                <h1 className={styles['name-h']}>{lang.tr_nameOfOrganization}</h1>
                <h1 className={styles['name-h']}>{lang.tr_nameOfOrganization2}</h1>
            </div>
        </Link>}
        <Menu/>
        <div className={styles['search-lang-end']}>
            <div className={styles['search-lang-div']}>
                <Search />
                <Lang/>
            </div>
        </div>
    </div>
  )
}
